import { createApp } from 'vue'   
import Header from './components/Header.vue'
import Footer from './components/Footer.vue' 
  
import 'bootstrap';
 

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
 
function generateUniqueId() {
    // Generate a random number and concatenate with current timestamp
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }
const uniqueId = generateUniqueId(); // You need to implement generateUniqueId() function
const uniqueId1 = generateUniqueId(); // You need to implement generateUniqueId() function

 createApp(Header).mount('#header')
 createApp(Footer).mount('#footer')

// Conditional component imports and mounts
if (document.querySelector('#plan')) {
  import('./components/Plan.vue').then(({ default: Plan }) => {
    createApp(Plan, { uniqueId }).mount('#plan')
  });
}

if (document.querySelector('#planbottom')) {
  import('./components/Plan.vue').then(({ default: Plan }) => {
    createApp(Plan, { uniqueId: uniqueId1 }).mount('#planbottom')
  });
}

if (document.querySelector('#homeplan')) {
  import('./components/HomePlan.vue').then(({ default: HomePlan }) => {
    createApp(HomePlan, { uniqueId: uniqueId1 }).mount('#homeplan')
  });
}

if (document.querySelector('#faq')) {
  import('./components/Faq.vue').then(({ default: Faq }) => {
    createApp(Faq).mount('#faq')
  });
} 
 


if (document.querySelector('#thankyou')) {
  import('./components/Thankyou.vue').then(({ default: Thankyou }) => {
    createApp(Thankyou).mount('#thankyou')
  });
}

if (document.querySelector('#contact')) {
  import('./components/Contact.vue').then(({ default: Contact }) => {
    createApp(Contact).mount('#contact')
  });
}

if (document.querySelector('#paypalprocess')) {
  import('./components/PaypalProcess.vue').then(({ default: PaypalProcess }) => {
    createApp(PaypalProcess).mount('#paypalprocess')
  });
}

 