<template>
  
  <nav class="navbar navbar-expand-lg justify-content-between">
      <div class="container-fluid navbar__container my-0">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"><i class="fa fa-navicon"></i></span>
        </button>
        <div>
        <a class="navbar-brand d-none d-lg-block" href="/"><img src="images/logo.png"></a>
        <a class="navbar-brand d-md-block d-lg-none " href="/"><img src="images/chattr-logo-small.png" width="139" height="49"></a>
        <div class="d-lg-none mt-0">
          <a href="https://uk.trustpilot.com/review/chattr.co.uk" target="_blank"
              rel="noopener noreferrer" class="ratings">
              <div class="stars-container mt-1 d-flex">
                  <div class="stars"> 
                      <div class="stars__group">
                          <i class="stars__star fa fa-star stars__star--yellow"></i>
                      </div>
                      <div class="stars__group">
                          <i class="stars__star fa fa-star stars__star--yellow"></i>
                      </div>
                      <div class="stars__group">
                          <i class="stars__star fa fa-star stars__star--yellow"></i>
                      </div>
                      <div class="stars__group">
                          <i class="stars__star fa fa-star stars__star--yellow"></i>
                      </div>
                      <div class="stars__group">
                          <i class="stars__star fa fa-star stars__star--grey"></i>
                          <i class="stars__star fa stars__star--yellow"></i>
                      </div>
                  </div>
                  <p class="stars__text">Trustpilot <strong>4</strong> / 5</p>
              </div>
          </a>
        </div>
        </div>
        <a class="d-md-block d-lg-none cart-icon-mb" href="/plans"><i class="fa-solid  fa-cart-shopping fa-2x"></i></a>


        <div class="collapse navbar-collapse navbar__link-container" id="navbarNavDropdown">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" aria-label="menu" href="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-label="menu" href="/plans">Plans</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-label="menu" href="/how-to-start">How to get started</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-label="menu" href="/contact-us">Contact Us</a>
            </li>
             
          </ul>
                <div class="d-none d-lg-block">
                    <a href="https://uk.trustpilot.com/review/chattr.co.uk" target="_blank"
                        rel="noopener noreferrer" class="ratings">
                        <div class="stars-container mt-2 mb-2">
                            <div class="stars"> 
                                <div class="stars__group">
                                    <i class="stars__star fa fa-star stars__star--yellow"></i>
                                </div>
                                <div class="stars__group">
                                    <i class="stars__star fa fa-star stars__star--yellow"></i>
                                </div>
                                <div class="stars__group">
                                    <i class="stars__star fa fa-star stars__star--yellow"></i>
                                </div>
                                <div class="stars__group">
                                    <i class="stars__star fa fa-star stars__star--yellow"></i>
                                </div>
                                <div class="stars__group">
                                    <i class="stars__star fa fa-star stars__star--grey"></i>
                                    <i class="stars__star fa  stars__star--yellow"></i>
                                </div>
                            </div>
                            <p class="stars__text">Trustpilot <strong>4</strong> / 5</p>
                        </div>
                    </a>
                </div>
          <form class="form-inline">
             <a class="d-none d-lg-inline-block cart-icon-mb" href="/plans"><i class="fa-solid  fa-cart-shopping fa-2x"></i></a>
            <button class="btn1 btn-primary1 headerbtn" type="button"><a href="https://portal.chattr.co.uk/auth/login"><i class="fa-solid fa-user-minus"></i>Sign In</a></button>
          </form>
        </div> 
      </div>
    </nav> 
  <cookie v-if="showCookie && isHomePage" ></cookie>
</template>

<script>
import { ref, onMounted, defineAsyncComponent,computed } from "vue";

export default {
  components: {
    Cookie: defineAsyncComponent(() => import('./Cookie.vue'))
  },
  setup() {
    const showCookie = ref(false);
    const showCookiePlaceholder = ref(true);

    const isHomePage = computed(() => window.location.pathname === '/');

    onMounted(() => {
      setTimeout(() => {
        showCookiePlaceholder.value = false;
        showCookie.value = true;
      }, 8000); // Adjust the delay as needed
    });

    return {
      showCookie,
      showCookiePlaceholder,
      isHomePage
    };
  }
};
</script>

<style lang="scss">
 @import "@/styles/app.scss";
 
</style>
