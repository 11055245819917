<template>
  <footer class="main-footer text-lg-start border border-white mt-xl-5 pt-4">
    <!-- Grid container -->
    <div class="desktop-footer-padding container p-4">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-lg-4 col-12 mb-lg-0">
          <div class="rounded-circle d-flex align-items-center justify-content-center mb-4 mx-auto">
            <img src="images/footer-logo.png" loading="lazy" />
          </div>
        </div>
        <!-- Grid column -->

        <!-- Footer section 1 
        <div class="footer-sec col-lg-2 col-6 mb-4 mb-lg-0 px-3">
          <h5 class="footer-sec-title text-uppercase mb-2">Products</h5>
          <ul class="list-unstyled mb-4">
            <li><a href="#!" class="text-black">Product 1</a></li>
            <li><a href="#!" class="text-black">Product 2</a></li>
            <li><a href="#!" class="text-black">Product 3</a></li>
          </ul>
        </div>

         
        <div class="footer-sec col-lg-2 col-6 mb-4 mb-lg-0 px-3">
          <h5 class="footer-sec-title text-uppercase mb-2">Services</h5>
          <ul class="list-unstyled">
            <li><a href="#!" class="text-black">Service 1</a></li>
            <li><a href="#!" class="text-black">Service 2</a></li>
            <li><a href="#!" class="text-black">Service 3</a></li>
          </ul>
        </div>
-->
        <!-- Footer section 3 -->
        <div class="footer-sec col-lg-4 col-12 mb-4 mb-lg-0 px-3">
          <div class="rounded-circle d-flex align-items-center justify-content-center flex-column mb-4 mx-auto">
            <h5 class="footer-sec-title text-uppercase mb-2">What do you need?</h5>
            <ul class="list-unstyled">
              <!-- <li><a href="#!" class="text-black">Our Story</a></li>
              <li><a href="#!" class="text-black">Team</a></li> -->
              <li><a href="/how-to-start" class="text-black">How to get started</a></li>
              <li><a href="https://portal.chattr.co.uk/" class="text-black">Your Portal - login</a></li>
              <li><a href="https://portal.chattr.co.uk/page/faqs" class="text-black">FAQs</a></li> 
              <li><a href="/plans" class="text-black">Plans</a></li>
              <li><a href="/contact-us" class="text-black">Contact Us</a></li>
            </ul>
          </div>
        </div>

        <!-- Footer section 4 (visible only on small screens) 
        <div class="footer-sec d-md-block d-lg-none col-lg-2 col-6 mb-4 mb-lg-0 px-3">
          <h5 class="footer-sec-title text-uppercase mb-2">Social Media</h5>
          <ul class="list-unstyled">
            <li><a href="#!" class="text-black">Instagram</a></li>
            <li><a href="#!" class="text-black">Twitter</a></li>
            <li><a href="#!" class="text-black">Facebook</a></li>
          </ul>
        </div>-->

        <!-- Footer section 5 -->
        <div class="col-lg-4 col-12 mb-2 mb-lg-0">
          <!--<h5 class="subscribe-txt mb-4">Sign Up to Our Newsletter!</h5>
          <div class="row">
            <div class="col-lg-12 col-md-12 footer-subscribe-box">
              <div class="form-outline form-white mb-2 footer-subscribe-left">
                <input
                  type="email"
                  id="form5Example2"
                  class="px-4 form-control form-subscribe"
                  placeholder="Email Address"
                />
              </div>
              <button
                type="submit"
                class="footer-subscribe-right d-flex justify-content-center px-4 mb-4 btn btn-subscribe btn-block"
              >
                Subscribe
              </button>
            </div>
          </div>-->

          <div class="desktop-social-section d-flex justify-content-between px-5 pt-3">
            <div class="desktop-social-icon"><a href="https://www.instagram.com/chattr.uk/" target="_blank"><img src="images/instagram.png" width="37" height="37" /></a></div>
            <div class="desktop-social-icon"><img src="images/ticon.png" width="37" height="37"  /></div>
            <div class="desktop-social-icon"><a href="https://www.facebook.com/profile.php?id=61559100785203" target="_blank"><img src="images/fbicon.png" width="37" height="37" /></a></div>
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
    <!-- Grid container -->
    <div class="footer-terms">
      <ul class="m-0 p-0 mb-2">
        <li><a class="text-black" href="/privacy">Privacy Policy</a></li>
        <li><a class="text-black" href="">Cookies</a></li>
        <li><a class="text-black" href="/terms">Terms & Conditions</a></li>
      </ul>
    </div>
    <!-- Copyright -->
    <div class="footer-bottom-bg text-center p-4 border-top border-white">Chattr ©2024. All rights reserved</div>

    <!-- Copyright -->
  </footer>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  components: {},
  setup() {
    onMounted(() => {
      // Add any logic you need after the component is mounted
    });
  },
};
</script>

 